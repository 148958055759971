import Banner from "../containers/WebApp/Philantropic/Banner";
import Section from "../containers/WebApp/Philantropic/Section";
import SEO from "../components/SEO";
import React from "react";

const PhilantropicInitiatives = () => {
  return (
    <>
      <SEO title="Philantropic Initiatives" />
      <Banner />
      <Section />
    </>
  );
};

export default PhilantropicInitiatives;
